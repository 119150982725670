import React from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';

import Layout from 'layout/Layout';
import Seo from 'components/Seo';
import { breakpoints } from 'lib/constants';
import { pxToRem } from 'lib/utils';
import BlockContentHomePage from 'components/BlockContentHomePage';

export const query = graphql`
  query HomePage {
    sanityPost(id: { eq: "-2836d376-75f6-54c5-989a-72cf3f7c4436" }) {
      id
      title
      _rawBody(resolveReferences: { maxDepth: 10 })
    }
  }
`;

const StyledMainHeading = styled.h1`
  font-weight: 700;
  line-height: 1.3;
  text-align: center;
  text-transform: uppercase;

  @media screen and (max-width: ${pxToRem(breakpoints.large)}) {
    font-size: ${pxToRem('26px')};
    letter-spacing: ${pxToRem('2px')};
  }

  @media screen and (min-width: ${pxToRem(breakpoints.large)}) {
    max-width: ${pxToRem('1000px')};
    margin-right: auto;
    margin-left: auto;
    margin-top: 10vh;
    font-size: ${pxToRem('34px')};
    letter-spacing: ${pxToRem('3px')};
  }
`;

const IndexPage = (props) => {
  return (
    <Layout uri={props.uri}>
      <Seo />
      <article>
        <StyledMainHeading>Monrif</StyledMainHeading>
        <StaticQuery
          query={query}
          render={(data) => {
            const { _rawBody } = data.sanityPost;
            return <BlockContentHomePage blocks={_rawBody} />;
          }}
        />
      </article>
    </Layout>
  );
};

export default IndexPage;
