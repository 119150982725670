import BaseBlockContent from '@sanity/block-content-to-react';
import React from 'react';

import Attachment from 'components/Attachment';
import AttachmentList from 'components/AttachmentList';
import Figure from 'components/Figure';
import Table from 'components/Table';
import ComponentList from 'components/ComponentList';
import ArticleHeading from 'components/ArticleHeading';
import SimpleParagraph from 'components/SimpleParagraph';
import styled from 'styled-components';
import { pxToRem } from 'lib/utils';

const StyledSectionHeader = styled.header`
  margin-top: ${pxToRem('50px')};
  text-align: center;
`;

const list = (props) => <ul className="wysiwyg">{props.children}</ul>;

const targetAndClassName = ({ mark, children }) =>
  mark.blank ? (
    <a
      href={mark.href}
      target="_blank"
      rel="noopener noreferrer"
      className={mark.poligrafici ? 'poligrafici' : ''}
    >
      {children}
    </a>
  ) : (
    <a href={mark.href} className={mark.poligrafici ? 'poligrafici' : ''}>
      {children}
    </a>
  );

const marks = { link: targetAndClassName };

const types = {
  table(props) {
    return <Table data={props.node} />;
  },
  block(props) {
    switch (props.node.style) {
      case 'h3':
        return (
          <StyledSectionHeader>
            <ArticleHeading headingType="h2">{props.children}</ArticleHeading>
          </StyledSectionHeader>
        );

      case 'h4':
        return (
          <StyledSectionHeader>
            <ArticleHeading headingType="h4">{props.children}</ArticleHeading>
          </StyledSectionHeader>
        );

      case 'h5':
        return (
          <StyledSectionHeader>
            <ArticleHeading headingType="h5">{props.children}</ArticleHeading>
          </StyledSectionHeader>
        );

      case 'h6':
        return (
          <StyledSectionHeader>
            <ArticleHeading headingType="h6">{props.children}</ArticleHeading>
          </StyledSectionHeader>
        );

      default:
        return <p>{props.children}</p>;
    }
  },
  figure(props) {
    return <Figure {...props.node} />;
  },
  attachment(props) {
    return <Attachment {...props.node} />;
  },
  attachmentList(props) {
    return <AttachmentList data={props.node.attachmentList} />;
  },
  componentList(props) {
    return <ComponentList data={props.node.components} />;
  },
  simpleParagraph(props) {
    return <SimpleParagraph data={props.node} />;
  },
};

const serializers = {
  list,
  marks,
  types,
};

const BlockContent = ({ blocks }) => (
  <BaseBlockContent className="homeList" blocks={blocks} serializers={serializers} />
);

export default BlockContent;
